export class MainView {

    init() {

        // function firstViewLoad() {
        //     const scrollTop = window.scrollY || document.documentElement.scrollTop;
        //     const mv = document.querySelectorAll('.p-mv');
        //     if( scrollTop < 30){
        //         mv.forEach(obj => {
        //             obj.classList.add('is-show');
        //         });
        //     }
        // }
        // firstViewLoad();
        
        // function topAnimation() {
        //     const copy = document.querySelectorAll('.js-copy');
        //     const lead = document.querySelectorAll('.js-lead');
        //     const overlay = document.querySelectorAll('#js-overlay');
        //     const mv = document.querySelectorAll('#js-mv');
        //     const scrollTop = window.scrollY || document.documentElement.scrollTop;
        //     if( scrollTop > 30){
        //         copy.forEach(obj => {
        //             obj.classList.add('is-hide');
        //         });
        //         overlay.forEach(obj => {
        //             obj.classList.add('is-show');
        //         });
        //         lead.forEach(obj => {
        //             obj.classList.add('is-show');
        //         });
        //         if( scrollTop > 400 ){
        //             mv.forEach(obj => {
        //                 obj.classList.remove('is-show');
        //             });
        //             overlay.forEach(obj => {
        //                 obj.classList.remove('is-show');
        //             });
        //         } else {
        //             mv.forEach(obj => {
        //                 obj.classList.add('is-show');
        //             });
        //         }
        //     } else {
        //         copy.forEach(obj => {
        //             obj.classList.remove('is-hide');
        //         });
        //         overlay.forEach(obj => {
        //             obj.classList.remove('is-show');
        //         });
        //         lead.forEach(obj => {
        //             obj.classList.remove('is-show');
        //         });
        //     }
        // }
        // window.addEventListener('scroll', topAnimation);
    }
}