import { Common } from "./common";
import { MainView } from './mainView';
//import Swiper from 'swiper/bundle';

import './Sass/foundation/_global.scss';
import './Sass/foundation/_breakpoint.scss';
import './Sass/foundation/_mixin.scss';
import './Sass/foundation/_reset.scss';
import './Sass/foundation/_base.scss';
import './Sass/layout/_header.scss';
import './Sass/layout/_footer.scss';
import './Sass/layout/_main.scss';
import './Sass/layout/_nav.scss';
import './Sass/object/_component.scss';
import './Sass/object/_project.scss';
import './Sass/object/_utility.scss';
import './Sass/object/_external.scss';

import 'swiper/css/bundle';


// //店舗詳細 サムネイル
// let sliderThumbnail = new Swiper('.slider-thumbnail', {
//     slidesPerView: 4,
//     spaceBetween: 5,
//     freeMode: true,
//     watchSlidesVisibility: true,
//     watchSlidesProgress: true,
//     breakpoints: {
//         768: {
//             spaceBetween: 10,
//         }
//     }
// });

// //店舗詳細 メイン
// let slider = new Swiper('.slider', {
//     slidesPerView: 1,
//     loop: true,
//     speed: 1000,
//     autoplay: {
//         delay: 2000,
//     },
//     navigation: {
//         nextEl: '.mainSwiper-button-next',
//         prevEl: '.mainSwiper-button-prev',
//     },
//     thumbs: {
//         swiper: sliderThumbnail
//     },
//     effect: 'fade', 
// });

// //関連情報
// let infoSlider = new Swiper('.info-slider', {
//     slidesPerView: 2,
//     spaceBetween: 10,
//     loop: true,
//     speed: 1000,
//     autoplay: {
//         delay: 3000,
//     },
//     navigation: {
//         nextEl: '.infoSwiper-button-next',
//         prevEl: '.infoSwiper-button-prev',
//     },
//     breakpoints: {
//         768: {
//             slidesPerView: 4,
//             spaceBetween: 20,
//         }
//     }
// });


new MainView().init();
new Common().init();







